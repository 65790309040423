<template>
  <vx-card style="padding-left: 10px" v-if="roleFetched">
    <vs-row>
      <h5 class="mb-5">Assign / Revoke permissions for {{ roleName }}</h5>
    </vs-row>

    <vs-row class="mb-4 mt-4">
      <vs-checkbox v-model="checkAll" @change="checkAllPermissions"
        >Check All Permissions</vs-checkbox
      >
    </vs-row>

    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Admin Management
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewAdminManagement"
          >View</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.createAdminManagement"
          >Create</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.updateAdminManagement"
          >Update</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Email Templates
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewEmailTemplates">View</vs-checkbox>
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.updateEmailTemplates"
          >Update</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Page Management
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewPageManagement">View</vs-checkbox>
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.updatePageManagement"
          >Update</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Clinic Management
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewClinicManagement"
          >View</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.createClinicManagement"
          >Create</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.updateClinicManagement"
          >Update</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewClinicBankDetail"
          >View Bank Details</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewClinicTreatmentHistory"
          >Treatment History</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewClinicTreatmentDetail"
          >Treatment Detail</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.changeClinicPassword"
          >Change Password</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Franchise Management
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewFranchiseManagement"
          >View</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.createFranchiseManagement"
          >Create</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.updateFranchiseManagement"
          >Update</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewFranchiseAssociatedClinics"
          >View Associated Clinics</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.changeFranchisePassword"
          >Change Password</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Doctor Clinic Management
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewDoctorClinicManagement"
          >View</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.createDoctorClinicManagement"
          >Create</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.updateDoctorClinicManagement"
          >Update</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.exportDoctorClinicManagement"
          >Export</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Derma Specialist Management
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewDermaSpecialistManagement"
          >View</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.createDermaSpecialistManagement"
          >Create</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.updateDermaSpecialistManagement"
          >Update</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.exportDermaSpecialistManagement"
          >Export</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Doctor Management
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewDoctorManagement"
          >View</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.createDoctorManagement"
          >Create</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.updateDoctorManagement"
          >Update</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.exportDoctorManagement"
          >Export</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewDoctorBankDetails"
          >View Bank Details</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewDoctorAssociatedClinics"
          >View Associated Clinics</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.removeDoctorAssociatedClinics"
          >Remove Associated Clinics</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewDoctorTreatmentHistory"
          >Treatment History</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewDoctorTreatmentDetail"
          >Treatment Detail</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.changeDoctorPassword"
          >Change Password</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Nurse Management
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewNurseManagement"
          >View</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.createNurseManagement"
          >Create</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.updateNurseManagement"
          >Update</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.exportNurseManagement"
          >Export</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewNurseAssociatedClinics"
          >View Associated Clinics</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.removeNurseAssociatedClinics"
          >Remove Associated Clinics</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewNurseTreatmentHistory"
          >Treatment History</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewNurseTreatmentDetail"
          >Treatment Detail</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.changeNursePassword"
          >Change Password</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Patient Management
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewPatientManagement"
          >View</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.exportPatientManagement"
          >Export</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewPatientMedicalDetail"
          >View Medical Detail</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewPatientTreatmentHistory"
          >Treatment History</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewPatientTreatmentDetail"
          >Treatment Detail</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Product Management
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewProductManagement"
          >View</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.createProductManagement"
          >Create</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.updateProductManagement"
          >Update</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.editProductStatus"
          >Edit Status</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Applicable Areas
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewApplicableArea">View</vs-checkbox>
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.createApplicableArea"
          >Create</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.updateApplicableArea"
          >Update</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.deleteApplicableArea"
          >Delete</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Assign Clinic
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.assignClinic"
          >Assign Clinic</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <!-- <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Doctor Aggregated Report
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewDoctorAggregatedReport"
          >View</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Invoice
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewInvoice">View</vs-checkbox>
      </vs-col>
    </vs-row> -->
    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Invoices
      </vs-col>
      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewInvoices" vs-value="tax-invoice"
          >Tax Invoice</vs-checkbox
        >
      </vs-col>
      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox
          v-model="permissions.viewInvoices"
          vs-value="admin-store-invoice"
          >Admin Store Invoice</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Push Notification
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewPushNotification"
          >View</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.sendPushNotification"
          >Send</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Pair Device
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewPairDevice">View</vs-checkbox>
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.addPairDevice">Add</vs-checkbox>
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.removePairDevice">Remove</vs-checkbox>
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Safety Video
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.updateSafetyVideo"
          >Update</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Consent Questionnaire
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewConsentQuestionnaire"
          >View</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.createConsentQuestionnaire"
          >Create</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.updateConsentQuestionnaire"
          >Update</vs-checkbox
        >
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.deleteConsentQuestionnaire"
          >Delete</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-row class="mt-5">
      <vs-col
        vs-type="flex"
        vs-justify="left"
        class="mb-2 sidebar-title"
        vs-align="left"
        vs-w="12"
      >
        Settings
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.viewSettings">View</vs-checkbox>
      </vs-col>

      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="3"
      >
        <vs-checkbox v-model="permissions.updateSettings">Update</vs-checkbox>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full mt-5">
          <vs-button align="right" @click="submitHandler">Submit </vs-button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AssignPermissionToRole",
  data() {
    return {
      roleId: this.$route.params.roleId,
      roleName: "",
      roleFetched: false,
      checkAll: false,
      permissions: {
        viewAdminManagement: false,
        createAdminManagement: false,
        updateAdminManagement: false,
        viewEmailTemplates: false,
        updateEmailTemplates: false,
        viewPageManagement: false,
        updatePageManagement: false,
        viewClinicManagement: false,
        createClinicManagement: false,
        updateClinicManagement: false,
        viewClinicTreatmentHistory: false,
        viewClinicTreatmentDetail: false,
        viewClinicBankDetail: false,
        changeClinicPassword: false,
        viewFranchiseManagement: false,
        createFranchiseManagement: false,
        updateFranchiseManagement: false,
        viewFranchiseAssociatedClinics: false,
        changeFranchisePassword: false,
        viewDoctorClinicManagement: false,
        createDoctorCliicManagement: false,
        updateDoctorClinicManagement: false,
        exportDoctorClinicManagement: false,
        viewDoctorManagement: false,
        createDoctorManagement: false,
        updateDoctorManagement: false,
        exportDoctorManagement: false,
        viewDoctorBankDetails: false,
        viewDoctorAssociatedClinics: false,
        removeDoctorAssociatedClinics: false,
        viewDoctorTreatmentHistory: false,
        viewDoctorTreatmentDetail: false,
        changeDoctorPassword: false,
        viewNurseManagement: false,
        createNurseManagement: false,
        updateNurseManagement: false,
        exportNurseManagement: false,
        viewNurseAssociatedClinics: false,
        removeNurseAssociatedClinics: false,
        viewNurseTreatmentHistory: false,
        viewNurseTreatmentDetail: false,
        changeNursePassword: false,
        viewPatientManagement: false,
        exportPatientManagement: false,
        viewPatientMedicalDetail: false,
        viewPatientTreatmentHistory: false,
        viewPatientTreatmentDetail: false,
        viewProductManagement: false,
        createProductManagement: false,
        updateProductManagement: false,
        editProductStatus: false,
        viewApplicableArea: false,
        createApplicableArea: false,
        updateApplicableArea: false,
        deleteApplicableArea: false,
        assignClinic: false,
        viewDoctorAggregatedReport: false,
        viewInvoice: false,
        viewPushNotification: false,
        sendPushNotification: false,
        viewPairDevice: false,
        addPairDevice: false,
        removePairDevice: false,
        updateSafetyVideo: false,
        viewConsentQuestionnaire: false,
        createConsentQuestionnaire: false,
        updateConsentQuestionnaire: false,
        deleteConsentQuestionnaire: false,
        viewSettings: false,
        updateSettings: false,
      },
    };
  },
  methods: {
    ...mapActions("rolesAndPermissions", [
      "getRoleById",
      "savePermissionForRoles",
    ]),
    getRole() {
      this.$vs.loading();
      this.getRoleById(this.roleId)
        .then((result) => {
          this.roleName = result.data.data.role;
          if (
            result.data.data.permissions &&
            result.data.data.permissions !== null &&
            result.data.data.permissions !== {}
          )
            this.permissions = result.data.data.permissions;

          this.roleFetched = true;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            message: err.data.message,
            color: "danger",
          });
        });
    },
    submitHandler() {
      this.$vs.loading();
      this.savePermissionForRoles({
        roleId: this.roleId,
        permissions: this.permissions,
      })
        .then((result) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Success",
            text: "Permissions saved for the role successfully",
            color: "success",
          });
          const user = this.$store.state.AppActiveUser.userRole;
          if (user === "superAdmin") {
            this.$router.push("/super-admin/roles");
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Failed",
            text: err.data.message,
            color: "danger",
          });
        });
    },
    checkAllPermissions() {
      if (this.checkAll) {
        this.permissions = {
          viewAdminManagement: true,
          createAdminManagement: true,
          updateAdminManagement: true,
          viewEmailTemplates: true,
          updateEmailTemplates: true,
          viewPageManagement: true,
          updatePageManagement: true,
          viewClinicManagement: true,
          createClinicManagement: true,
          updateClinicManagement: true,
          viewClinicTreatmentHistory: true,
          viewClinicTreatmentDetail: true,
          viewClinicBankDetail: true,
          changeClinicPassword: true,
          viewFranchiseManagement: true,
          createFranchiseManagement: true,
          updateFranchiseManagement: true,
          viewFranchiseAssociatedClinics: true,
          changeFranchisePassword: true,
          viewDoctorClinicManagement: true,
          createDoctorClinicManagement: true,
          updateDoctorClinicManagement: true,
          exportDoctorClinicManagement: true,
          viewDoctorManagement: true,
          createDoctorManagement: true,
          updateDoctorManagement: true,
          exportDoctorManagement: true,
          viewDoctorBankDetails: true,
          viewDoctorAssociatedClinics: true,
          removeDoctorAssociatedClinics: true,
          viewDoctorTreatmentHistory: true,
          viewDoctorTreatmentDetail: true,
          changeDoctorPassword: true,
          viewNurseManagement: true,
          createNurseManagement: true,
          updateNurseManagement: true,
          exportNurseManagement: true,
          viewNurseAssociatedClinics: true,
          removeNurseAssociatedClinics: true,
          viewNurseTreatmentHistory: true,
          viewNurseTreatmentDetail: true,
          changeNursePassword: true,
          viewPatientManagement: true,
          exportPatientManagement: true,
          viewPatientMedicalDetail: true,
          viewPatientTreatmentHistory: true,
          viewPatientTreatmentDetail: true,
          viewProductManagement: true,
          createProductManagement: true,
          updateProductManagement: true,
          editProductStatus: true,
          viewApplicableArea: true,
          createApplicableArea: true,
          updateApplicableArea: true,
          deleteApplicableArea: true,
          assignClinic: true,
          viewDoctorAggregatedReport: true,
          viewInvoice: true,
          viewPushNotification: true,
          sendPushNotification: true,
          viewPairDevice: true,
          addPairDevice: true,
          removePairDevice: true,
          updateSafetyVideo: true,
          viewConsentQuestionnaire: true,
          createConsentQuestionnaire: true,
          updateConsentQuestionnaire: true,
          deleteConsentQuestionnaire: true,
          viewSettings: true,
          updateSettings: true,
        };
      } else {
        this.permissions = {
          viewAdminManagement: false,
          createAdminManagement: false,
          updateAdminManagement: false,
          viewEmailTemplates: false,
          updateEmailTemplates: false,
          viewPageManagement: false,
          updatePageManagement: false,
          viewClinicManagement: false,
          createClinicManagement: false,
          updateClinicManagement: false,
          viewClinicTreatmentHistory: false,
          viewClinicTreatmentDetail: false,
          viewClinicBankDetail: false,
          changeClinicPassword: false,
          viewFranchiseManagement: false,
          createFranchiseManagement: false,
          updateFranchiseManagement: false,
          viewFranchiseAssociatedClinics: false,
          changeFranchisePassword: false,
          viewDoctorClinicManagement: false,
          createDoctorClinicManagement: false,
          updateDoctorClinicManagement: false,
          exportDoctorClinicManagement: false,
          viewDoctorManagement: false,
          createDoctorManagement: false,
          updateDoctorManagement: false,
          exportDoctorManagement: false,
          viewDoctorBankDetails: false,
          viewDoctorAssociatedClinics: false,
          removeDoctorAssociatedClinics: false,
          viewDoctorTreatmentHistory: false,
          viewDoctorTreatmentDetail: false,
          changeDoctorPassword: false,
          viewNurseManagement: false,
          createNurseManagement: false,
          updateNurseManagement: false,
          exportNurseManagement: false,
          viewNurseAssociatedClinics: false,
          removeNurseAssociatedClinics: false,
          viewNurseTreatmentHistory: false,
          viewNurseTreatmentDetail: false,
          changeNursePassword: false,
          viewPatientManagement: false,
          exportPatientManagement: false,
          viewPatientMedicalDetail: false,
          viewPatientTreatmentHistory: false,
          viewPatientTreatmentDetail: false,
          viewProductManagement: false,
          createProductManagement: false,
          updateProductManagement: false,
          editProductStatus: false,
          viewApplicableArea: false,
          createApplicableArea: false,
          updateApplicableArea: false,
          deleteApplicableArea: false,
          assignClinic: false,
          viewDoctorAggregatedReport: false,
          viewInvoice: false,
          viewPushNotification: false,
          sendPushNotification: false,
          viewPairDevice: false,
          addPairDevice: false,
          removePairDevice: false,
          updateSafetyVideo: false,
          viewConsentQuestionnaire: false,
          createConsentQuestionnaire: false,
          updateConsentQuestionnaire: false,
          deleteConsentQuestionnaire: false,
          viewSettings: false,
          updateSettings: false,
        };
      }
    },
  },
  created() {
    this.getRole();
  },
};
</script>

<style scoped>
.sidebar-title {
  font-weight: 600;
}
</style>
